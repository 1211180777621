import React from "react"
import PropTypes from "prop-types"
import _get from 'lodash/get'
import { Col } from "antd"

import classnames from "classnames"
import { Link } from "gatsby"

import styles from "../BlockSolutions.module.css"
import Button from "../../../components/Button"

const CardSolutionsV1 = ({
    identification,
    card_size,
    image,
    name_market: title,
    description_small,
    link_text,
    button_text,
    action,
    className,
    ...props
  }) => {
    const backgroundUrl = _get(image, 'data.full_url')
    const cardClass = classnames(
      styles.generalHeight,
      styles.cardHeight,
      styles.cardWitdth,
      className
    )
    const productProps = {
      imageUrl: backgroundUrl,
      identification: identification,
      ...props
    }
  
    const isBig = card_size == 24
    const titleClass = classnames(
      "db pb12 f24 white b mh12 mh24-m mh24-l",
      isBig ? styles.f46 : "f32-m f32-l"
    )
  
    const descriptionClass = classnames(
      "f16 white pb12 ma0 mh12 mh24-m mh24-l",
      isBig ? styles.descriptionWidth : styles.descriptionWidthSmall
    )
    return (
      <Col span={card_size} className={cardClass}>
        <div
          className="h-100 pv12 pv16-m pv16-l br3 flex flex-column justify-between justify-start-m justify-start-l"
          style={{
            backgroundImage: `linear-gradient(to left, rgba(12, 42, 26, 0), rgba(0, 0, 0, 0.97)), url(${backgroundUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div>
            <span className={titleClass}>{title}</span>
            <p className={descriptionClass}>{description_small}</p>
          </div>
          <div className="f16 mb8 mb16-m mb24-l mh12 mh24-m mh24-l">
            <Link
              to="/produto"
              state={productProps}
              className={LinkClass}
               data-tracking-id="GTM_WATCH_VIDEO"
               data-value={`Assista ao vídeo - ${identification}`}>
              {link_text}
            </Link>
          </div>
          <div>
            <Button
              onClick={() => action({ target: { id: title } })}
              type="solid"
              text={button_text}
              className={buttonClass}
              data-value={`Falar com gerente de soluções - ${identification}`}
              data-tracking-id="GTM_SOLUTIONS_MANAGER"
            />
          </div>
        </div>
      </Col>
    )
  }
  
  const LinkClass = classnames("underline", styles.spingGreen, styles.outline)
  const buttonClass = classnames("mb8 mh12 mh24-m mh24-l", styles.forcePh12Sm)
  
  CardSolutionsV1.propTypes = {
    card_size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    background: PropTypes.string,
    title: PropTypes.string,
    description_small: PropTypes.string,
    reference: PropTypes.string,
    link_text: PropTypes.string,
    button_text: PropTypes.string,
    action: PropTypes.func,
    className: PropTypes.string,
    identification: PropTypes.string,
  }
  
  CardSolutionsV1.defaultProps = {
    action: () => {},
  }

  export default CardSolutionsV1;